<template>
  <InputYearly
    v-model="profiles"
    :num_years="num_years"
    :label="title"
    :hint="`${hint}. Enter a name to upload a new profile`"
    :chart="profileDygraph"
    @change="$emit('change')"
    :table-input="false"
    :fill="fill"
    :fill-value="fillValue"
  >
    <template #default="{ value: val, year }">
      <InputProfile
        v-model="val[year - 1]"
        :profile-type="profileType"
        :label="`Year ${year}`"
        @change="$emit('change')"
      ></InputProfile>
    </template>
  </InputYearly>
</template>

<script>
import { mapGetters } from "vuex";

import ChartYearly from "@/components/charts/ChartYearly";
import ChartLine from "@/components/charts/ChartLine";

export default {
  name: "InputYearlyProfile",
  components: {
    InputYearly: () => import("@/components/InputYearly.vue"),
    InputProfile: () => import("@/components/InputProfile.vue"),
  },
  props: {
    profileType: {
      type: String,
      required: true,
    },
    num_years: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      required: false,
      default: "Profile",
    },
    scaleProfile: {
      type: Function,
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    fillValue: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    profiles: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change");
      },
    },
    ...mapGetters({
      getProfile: "profiles/get",
    }),
    profileDygraph() {
      return {
        title: this.title,
        component: ChartYearly,
        props: {
          chartComponent: ChartLine,
          data: this.chartData,
          options: {
            legend: "always",
            ylabel: "Demand (MW)",
            xlabel: "Hour",
            showRangeSelector: true,
          },
        },
      };
    },
    chartData() {
      return this.value.map((e, year) => {
        let data = e;
        let profileName = null;
        if (Number.isInteger(data)) {
          const profile = this.getProfile(data);
          if (!this.$utils.getIfExists(profile, ["value"])) {
            return null;
          }
          data = profile.value;
          profileName = profile.name;
        }

        if (this.scaleProfile) {
          data = this.scaleProfile(data, year);
        }
        return {
          name: profileName,
          value: data,
        };
      });
    },
  },
  methods: {},
};
</script>

<style></style>

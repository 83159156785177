<template>
  <Dygraph v-if="chartData != null" :data="chartData" :options="options"></Dygraph>
</template>

<script>
export default {
  name: "ChartLine",
  mixins: [],
  components: {
    Dygraph: () => import("@/components/charts/Dygraph"),
  },
  props: {
    data: {
      type: [Object, Array],
      required: false,
      default: () => null,
    },
    options: {
      type: Object,
      required: true,
    },
    nameKey: {
      type: String,
      required: false,
      default: "name",
    },
    valueKey: {
      type: String,
      required: false,
      default: "value",
    },
    startDate: {
      type: Date,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      profileIds: null,
    };
  },
  computed: {
    chartData() {
      let data = this.datasets;
      if (data == null) return null;
      data = this.mapDatasets(data);
      return data;
    },
    datasets() {
      if (this.data == null) return null;
      if (!Array.isArray(this.data)) return [this.data];
      return this.data;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    mapDatasets(datasets) {
      if (!Array.isArray(datasets[0][this.valueKey])) return null;
      const data = datasets[0][this.valueKey].map((e, hour) =>
        datasets.reduce(
          (acc, set) => {
            acc[set[this.nameKey]] = set[this.valueKey][hour];
            return acc;
          },
          { hour }
        )
      );
      return data;
    },
  },
};
</script>

<style></style>
